<template>
    <div>
         <b-form @submit.prevent="scanTrackingNumber">
            <div class="row mb-3 mt-4">
                <div class="col-sm-5 m-auto">
                    <div class="input-group mb-4">
                        <b-form-input type="text" tabindex="1"  required autocomplete="off" ref="trackingNumber" autofocus v-model=trackingNumber placeholder="Scan or Enter tracking number" ></b-form-input>
                        <div class="input-scan">
                            <svg tabindex="2" xmlns="http://www.w3.org/2000/svg" width="20" fill="currentColor" class="bi bi-upc-scan" viewBox="0 0 16 16">
                                <path d="M1.5 1a.5.5 0 0 0-.5.5v3a.5.5 0 0 1-1 0v-3A1.5 1.5 0 0 1 1.5 0h3a.5.5 0 0 1 0 1h-3zM11 .5a.5.5 0 0 1 .5-.5h3A1.5 1.5 0 0 1 16 1.5v3a.5.5 0 0 1-1 0v-3a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 1-.5-.5zM.5 11a.5.5 0 0 1 .5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 1 0 1h-3A1.5 1.5 0 0 1 0 14.5v-3a.5.5 0 0 1 .5-.5zm15 0a.5.5 0 0 1 .5.5v3a1.5 1.5 0 0 1-1.5 1.5h-3a.5.5 0 0 1 0-1h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 1 .5-.5zM3 4.5a.5.5 0 0 1 1 0v7a.5.5 0 0 1-1 0v-7zm2 0a.5.5 0 0 1 1 0v7a.5.5 0 0 1-1 0v-7zm2 0a.5.5 0 0 1 1 0v7a.5.5 0 0 1-1 0v-7zm2 0a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-7zm3 0a.5.5 0 0 1 1 0v7a.5.5 0 0 1-1 0v-7z"></path>
                            </svg>
                        </div>
                    </div>
                </div>
            </div>
        </b-form>

        <div v-if="isBusy" class="text-center small">
             <b-spinner></b-spinner>
        </div>
        <div class = "mt-3 mb-5">
                <div v-if="trackingData.length > 0" class="row">
                    <b-badge variant="primary" class =" ml-3 h5" style = "font-size:1.1em;" >Scanned Item List </b-badge>
                    <div class="col-sm-12">
                        <table class="table table-bordered">
                            <tr class="bg-light">
                                <th>AWB</th>
                                <th>Hub Code</th>
                                <th>In Scan Date</th>
                            </tr>
                            <tr v-for="item in trackingData" :key="item.id">
                                <td>{{item.awb}}</td>
                                <td>{{item.hub_code}}</td>
                                <td>{{item.created | date}} <small>{{item.created | time}}</small></td>
                            </tr>
                        </table>
                    </div>
                </div>
                <div v-else class="text-center">
                    <EmptyPageVue />
                </div>
        </div>
    </div>
</template>
<script>

import EmptyPageVue from './layout/EmptyPage.vue';
export default {
    name: "Inscan",
    title: "InscanHub",
    components: {
        EmptyPageVue
    },

    data() {
        return {
            trackingNumber: null,
            isBusy: false,
            trackingData: [],
        }
    },

    created() {
        this.getTrackingData()		
    },

    methods: {

        async getTrackingData(){
            try {
                this.isBusy = true
                let hubCode = this.$store.getters.user.email.split('@')[0]
                const res = await this.axios.get(`/inscan-hub/${hubCode}`)
                if(res.data.success) {
                    this.trackingData = res.data.result
                }
            } catch (err) {
                console.log(err)
            }
            this.isBusy = false;
        },

        async scanTrackingNumber() {
            try {
                this.isBusy = true;
                let hubCode = this.$store.getters.user.email.split('@')[0]

                if(this.trackingNumber.length > 28) {
                    await this.trimLMOrTrackingNumber();
                }

                let res = await this.axios.post('/inscan-hub', {trackingNumber :this.trackingNumber.toUpperCase().trim(), hubCode : hubCode});
                if(res.data.success) {
                    this.popToast('Success', 'Tracking has been updated', 'success')
                    this.trackingNumber = null,
                    this.trackingData = res.data.result
                }

                this.isBusy = false;
            } catch (error) {
                this.isBusy = false;
                console.log(error)
                this.popToast('Failed', error.response.data.message, 'danger')
            }
        },

        async trimLMOrTrackingNumber() {

            try {
                   
                let trimmedTrackingNumber = this.trackingNumber.substring(8).toUpperCase().trim();
                let trimmedCarrierIdRes = await this.axios.post('/carrier-id', {lm_awb: trimmedTrackingNumber})

                if (!(trimmedCarrierIdRes.data && trimmedCarrierIdRes.data.success)) {
                    trimmedTrackingNumber = this.trackingNumber.substring(8,22).toUpperCase().trim();
                    trimmedCarrierIdRes = await this.axios.post('/carrier-id', {lm_awb: trimmedTrackingNumber})
                }
                
                if(trimmedCarrierIdRes.data && trimmedCarrierIdRes.data.success){
                    let carrier_id = 0;
                    carrier_id = trimmedCarrierIdRes.data.carrier_id
                    if(this.trackingNumber.length > 28 && carrier_id != 2 && carrier_id != 7 && carrier_id != 20) {
                        
                        this.trackingNumber = this.trackingNumber.substring(8);
                    }
                    else if(carrier_id == 20) {
                        this.trackingNumber = this.trackingNumber.substring(8, 22)
                    }
                }    

            }
            catch (error) {
                console.log(error)
            }  
              
        }
    },
}
</script>
